.event-detail {
	display: flex;
	padding: 50px;
	flex-wrap: wrap;
	height: 100%;

	div:first-of-type {
		margin-right: 50px;
	}

	.details {
		background-color: var(--chrome-color);
		width: 100%;
		padding: 20px;
		display: flex;
		justify-content: space-between;
		dl {
			flex-basis: 50%;
			dt {
				font-weight: bold;
				&::after {
					content: ":";
					margin-right: 10px;
				}
			}

			dd {
			}
		}
	}

	.description {
		position: relative;

		.btn-take-part {
			position: absolute;
			bottom: 0;
		}
	}
	.hero-img {
		-webkit-user-drag: none;
		user-select: none;
		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
	}
}
.hero-img {
	object-fit: cover;
	-webkit-user-drag: none;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}
