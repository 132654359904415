.container {
	h2 {
		margin-bottom: 26px;
		display: flex;
		vertical-align: middle;

		div {
			margin-right: 14px;
			margin-top: -1%;
		}
	}

	.content {
		display: flex;
		vertical-align: middle;
	}
	.name {
		font-weight: bold;
		vertical-align: middle;
		text-align: center;
		font-size: x-large;
	}
}
.profileItem {
	vertical-align: text-top;
	font-size: x-large;
	text-align: start;
}
.profileItemEye {
	display: flex;
	margin-top: 20%;
	margin-bottom: 10%;
	vertical-align: middle;
}
.profileItemIcon {
	display: flex;
	margin-top: 20%;
	margin-bottom: 10%;
	margin-right: 25%;
	vertical-align: middle;
}
.profileItemIconHouse {
	display: flex;
	margin-top: 20%;
	margin-bottom: 10%;
	margin-right: 25%;
	padding-top: 12%;
	vertical-align: middle;
}
.avatar {
	display: flex;

	vertical-align: middle;
	max-width: fit-content;
	max-height: 180px;
	&.drag-active {
		opacity: 0.2;
	}
}
