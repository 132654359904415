ion-alert {
	//background color
	.alert-wrapper.sc-ion-alert-md {
		background-color: var(--ion-color-amber-300);
		--background: var(--ion-color-amber-300);
		box-shadow: var(--ion-color-amber-500);
	}
	.alert-wrapper {
		.alert-button:first-child {
			color: var(--ion-color-amber-900);
		}
		.alert-button:nth-child(2) {
			color: green;
		}
	}
	// Header Text
	.alert-title.sc-ion-alert-md {
		background-color: var(--ion-color-amber-300);
		color: var(--ion-color-amber-900);
	}
	.alert-checkbox-group.sc-ion-alert-md {
		background-color: var(--ion-color-amber-50);
		color: var(--ion-color-amber-900);
	}
	.alert-button-group.sc-ion-alert-md {
		background-color: var(--ion-color-amber-50);
		color: var(--ion-color-amber-900);
	}
}
button.alert-button.alert-button-confirm {
	background-color: var(--ion-color-success);
	color: var(--ion-color-success-contrast);
}
ion-alert.custom-alert {
	--backdrop-opacity: 0.7;
}

.custom-alert .alert-button-group {
	padding: 8px;
}

button.alert-button.alert-button-confirm {
	background-color: var(--ion-color-success);
	color: var(--ion-color-success-contrast);
}

.md button.alert-button.alert-button-confirm {
	border-radius: 4px;
}

.ios .custom-alert button.alert-button {
	border: 0.55px solid rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.2);
}

.ios button.alert-button.alert-button-cancel {
	border-right: 0;
	border-bottom-left-radius: 13px;
	border-top-left-radius: 13px;
}

.ios button.alert-button.alert-button-confirm {
	border-bottom-right-radius: 13px;
	border-top-right-radius: 13px;
}
