.card-hero::part(image) {
	height: 280px;
	-webkit-user-drag: none;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	object-fit: cover;
	aspect-ratio: 4 / 3;
}
.card-content {
	min-height: 100px;
	text-overflow: ellipsis;
}
.card-title {
	width: 400px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
ion-card .card-avatar-wrapper {
	position: absolute;
	left: 50%;
	top: 100px;
	transform: translateX(-50%) translateY(-50%);
}

ion-card .card-avatar-wrapper ion-img.card-avatar {
	width: 160px;
	height: 160px;
}

ion-card .card-avatar-wrapper ion-img.card-avatar::part(image) {
	border-radius: 80px;
}

ion-card .card-avatar-wrapper ion-button {
	--border-width: 1px;
	--border-color: var(--ion-color-light-shade);
	--border-style: solid;
	--padding-start: 0;
	--padding-end: 0;
	--padding-bottom: 0;
	--padding-top: 0;

	--border-radius: 50%;
	height: 38px;
	width: 38px;

	position: absolute;
	right: 0px;
	bottom: 0px;
}

ion-card .card-avatar-wrapper ion-button ion-icon {
	width: 28px;
	height: 28px;
}

// override when smaller screen
@media only screen and (min-width: 350) and (max-width: 450px) {
	.card-title {
		width: 10px;
	}
}
@media only screen and (min-width: 450px) and (max-width: 570px) {
	.card-title {
		width: 400px;
	}
}
@media only screen and (min-width: 570px) and (max-width: 768px) {
	.card-title {
		width: 280px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 784px) {
	.card-title {
		width: 200px;
	}
}
@media only screen and (min-width: 784px) and (max-width: 972px) {
	.card-title {
		width: 235px;
	}
}
@media only screen and (min-width: 972px) and (max-width: 1096px) {
	.card-title {
		width: 180px;
	}
}
@media only screen and (min-width: 1096px) and (max-width: 1333px) {
	.card-title {
		width: 210px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
@media only screen and (min-width: 1333px) and (max-width: 1569px) {
	.card-title {
		width: 320px;
	}
}
