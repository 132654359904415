.titleBox {
	display: inline-block;
}
.avatarBox {
	display: block;
	margin-left: auto;
	margin-right: 0;
	width: fit-content;
	min-width: 30%;
	background-color: var(--toolbar-background) !important;
}
.backButtonBox {
	display: inline-block;
	margin-right: auto;
}
.titleRow {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: var(--toolbar-background) !important;
}
.backButtonBox {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: left;
	text-align: left;
	margin-right: auto;
}

.title {
	max-width: 600px;
	word-wrap: break-word;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
}
.logoImage {
	width: 15%;
	height: 100%;
	margin-left: 1.5%;
}
// override when smaller screen
@media only screen and (min-width: 100px) and (max-width: 500px) {
	.title {
		max-width: 245px;
		word-wrap: break-word;
	}
	.logoImage {
		width: 60%;
		height: 100%;
		margin-left: 3.6%;
	}
}
// override when smaller screen
@media only screen and (min-width: 501px) and (max-width: 768px) {
	.title {
		max-width: 200px;
		word-wrap: break-word;
	}
	.logoImage {
		width: 60%;
		height: 100%;
		margin-left: 1.5%;
	}
}
@media only screen and (min-width: 769px) and (max-width: 856px) {
	.title {
		max-width: 270px;
		word-wrap: break-word;
	}
	.logoImage {
		width: 80%;
		height: 100%;
		margin-left: 1.5%;
	}
}
.customtext {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
