ion-item-sliding.type-incoming ion-label,
ion-item-sliding.type-outgoing ion-label,
ion-item-sliding.type-general ion-label {
	padding-left: 10px;
}

ion-item-sliding.type-incoming ion-label {
	border-left: 2px solid var(--ion-color-invoicetype-incoming);
}

ion-item-sliding.type-outgoing ion-label {
	border-left: 2px solid var(--ion-color-invoicetype-outgoing);
}

ion-item-sliding.type-general ion-label {
	border-left: 2px solid var(--ion-color-invoicetype-general);
}
