ion-item-sliding.type-allgemein ion-label {
	border-left: 2px solid var(--ion-color-eventtype-allgemein);
	padding-left: 10px;
}

ion-item-sliding.type-fest ion-label {
	border-left: 2px solid var(--ion-color-eventtype-fest);
	padding-left: 10px;
}
ion-segment-button {
	--indicator-color: var(--ion-color-amber-400);
}

/* Material Design styles */
ion-segment-button.md {
	--color: #000;
	--color-checked: #08a391;
	--indicator-height: 4px;
}

/* iOS styles */
ion-segment-button.ios {
	--color: var(--ion-color-amber-900);
	--color-checked: var(--ion-color-amber-950);
	--border-radius: 20px;
}
