.eventModel {
	--height: 100%;
	--width: 100%;
}
.thumb {
	--size: 5000px;
}
.inprintText {
	white-space: pre-wrap;
}
.inprintTextPrivacy {
	padding-left: 4.5%;
}
.inprintContainer {
	display: flex;
	align-items: left;
	justify-content: center;
	vertical-align: middle;
	padding-bottom: 4%;
}
.inprintDivContainer {
	position: absolute;
	bottom: 0;
}
.firstDivider {
	padding-top: 1%;
	padding-bottom: 1%;
}
.eventDetails {
	padding-top: 2%;
	padding-bottom: 2%;
}
.userCard {
	max-width: 50%;
}
.customFooter {
	min-height: 10em;
	height: auto;
}
.buttomDivider {
	margin-top: 5%;
}
.nextButton {
	margin-bottom: 2%;
	margin-top: 2%;
}
.hero-imgModal {
	max-width: 50%;
}
.selectMaxWidth {
	width: 100%;
}
.rowItem {
	--ion-background-color: #fffbeb !important;
	background-color: #fffbeb !important;
}
.rowDivider {
	background-color: #f5f5f5 !important;
}
.rowDividerSuccess {
	background-color: #ecfccb !important;
}
.gridSuccess {
	background-color: #d9f99d;
}
.gridNoSuccess {
	background-color: #fffbeb;
}
.notDoneIcon {
	color: #854d0e;
	font-size: 48px;
	padding-top: 24px;
	padding-left: 10px;
	text-align: center;
}
.isDoneIcon {
	color: #3f6212;
	font-size: 48px;
	text-align: center;
	padding-top: 24px;
	padding-left: 10px;
}
.rowSelect {
	--placeholder-color: #b91c1c;
	--placeholder-opacity: 100%;
	font-size: 16px;
}
.buttonColor {
	color: #b91c1c;
}
