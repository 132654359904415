.info-block {
	h2 {
		margin-bottom: 26px;
		display: flex;
		vertical-align: middle;

		div {
			margin-right: 14px;
			margin-top: -1%;
		}
	}

	.content {
		background-color: var(--chrome-color);
		padding: 30px 18px;
		margin-bottom: 7px;

		&.no-background {
			background-color: transparent;
		}

		table {
			width: 100%;

			th {
				font-size: 16px;
				line-height: 19px;
				height: 33px;

				&:first-of-type {
					text-align: left;
				}

				&:last-of-type {
					text-align: right;
				}
			}

			tr td {
				border-bottom: 1px solid #939391;

				&:first-of-type {
					text-align: left;
				}

				&:last-of-type {
					text-align: right;
				}
			}
		}
	}
}
.infoBlockHeaderText {
	font-weight: bold;
	color: #f29939;
	font-size: large;
}
.infoBlockHeader {
	background-color: #d58732;
	color: #d58732;
}
.infoBlockHeaderSubTitle {
	padding-left: 5%;
	padding-bottom: -5%;
}
