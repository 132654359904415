.userAvatar {
	display: flex;
	justify-content: right;
}
.nameLabel {
	max-width: 10px;
	word-wrap: break-word;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
}
.logOutButton {
	color: transparent;
	background-color: transparent;
}
// override when smaller screen
@media only screen and (min-width: 100px) and (max-width: 500px) {
	.nameLabel {
		max-width: 50px;
		word-wrap: break-word;
	}
}
// override when smaller screen
@media only screen and (min-width: 501px) and (max-width: 768px) {
	.nameLabel {
		max-width: 50px;
		word-wrap: break-word;
	}
}
@media only screen and (min-width: 769px) and (max-width: 856px) {
	.nameLabel {
		max-width: 50px;
		word-wrap: break-word;
	}
}

.buttonCursor {
	cursor: pointer;
}
