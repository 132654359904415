.dashboardGrid {
	background-color: var(--ion-background-color) !important;
}

.dashboard-content {
	display: flex;
	justify-content: space-between;
	margin-top: 90px;

	> div {
		margin-left: 90px;
		flex-grow: 1;
		flex-basis: calc(100% / 3);
	}

	@media (max-width: 1366px) {
		flex-direction: column;
		margin-right: 20px;
		margin-top: 34px;

		> div {
			margin-left: 20px;
		}
	}
}
