.imageAvatar {
	height: 75%;
	width: 75%;
	max-width: 160px;
	border-radius: 60%;

	cursor: pointer;
	overflow: hidden;
}
.imageButton {
	padding: 0;

	position: absolute;
	cursor: none;
}
