.menuContent {
	background-color: var(--ion-color-amber-50);
}
ion-menu {
	width: fit-content;
	ion-content {
		--padding-top: 20px;
		--padding-bottom: 20px;
		--background: var(--ion-item-background, var(--ion-background-color, #fff));
	}

	.logo {
		margin: 30px auto 0;

		ion-img {
			height: fit-content;
			width: fit-content;
		}
	}
	.directory {
		padding-left: 6%;
	}
	.divider {
		margin-top: -8%;

		margin-bottom: 4%;
	}
	.divider2 {
		margin-top: 7%;
		margin-bottom: 0%;
	}
	.divider3 {
		margin-top: 0%;
		margin-bottom: 3%;
	}
	.topDiv {
		padding-top: 0.5%;
	}
	.topItem {
		padding-top: -3%;
		width: 100%;
	}
	.divTenantSelect {
		padding-top: 5%;
		width: 95%;
	}
	.avatar {
		width: 96px;
		height: 96px;
		border-radius: 48px;
		margin-bottom: 30px;
	}
	.inprintText {
		white-space: pre-wrap;
	}
	.inprintTextPrivacy {
		padding-left: 4.5%;
	}
	.inprintContainer {
		display: flex;
		align-items: left;
		justify-content: center;
		vertical-align: middle;
		padding-bottom: 4%;
	}
	.inprintDivContainer {
		position: absolute;
		bottom: 0;
	}
}

ion-menu.md {
	ion-list {
		padding: 20px 0;
		&:not(:last-of-type) {
			border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
		}
	}
	ion-list-header {
		padding-left: 18px;
		padding-right: 18px;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		font-weight: 450;
		color: var(--ion-color-step-650, #5f6368);
	}
	ion-item {
		--padding-start: 18px;
		margin-right: 10px;
		border-radius: 0 50px 50px 0;
		font-weight: 500;
		ion-icon {
			color: var(--ion-color-step-650, #5f6368);
		}
	}
	ion-item.selected {
		--background: rgba(var(--ion-color-primary-rgb), 0.14);

		ion-icon {
			color: var(--ion-color-amber-900);
		}
	}
}
ion-menu.ios {
	ion-list-header {
		padding-left: 16px;
		padding-right: 16px;
		margin-bottom: 8px;
	}
	ion-list {
		padding: 20px 0 0;
	}
	ion-item {
		--padding-start: 16px;
		ion-icon {
			font-size: 24px;
			color: #73849a;
		}
	}
	ion-item.selected {
		--background: rgba(var(--ion-color-primary-rgb), 0.14);

		ion-icon {
			color: var(--ion-color-amber-900);
		}
	}
}
@media only screen and (min-width: 0px) and (max-width: 856px) {
	ion-menu {
		width: 100%;
	}
}
