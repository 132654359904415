ion-datetime {
	--background: #fffbeb;
	--ion-text-color: #0d0d0d;
	border-radius: 16px;
	box-shadow: rgba(var(--ion-color-rose-rgb), 0.3) 0px 10px 15px -3px;
	--ion-color-shade: #0d0d0d;
	--ion-color-tint: #0d0d0d;
	--ion-color-contrast: #0d0d0d;
	--ion-color-contrast-rgb: #0d0d0d;
}
ion-datetime-button::part(native) {
	background: #fffbeb;
}
