ion-content {
	--background: var(--ion-color-amber-50);
}
ion-footer {
	background-color: var(--ion-color-amber-100);
	color: var(--ion-color-amber-50);
	--background: var(--ion-color-amber-50);
	--color: var(--ion-color-amber-50);
}
ion-page {
	--ion-background-color: var(--ion-color-amber-50);
	background-color: var(--ion-color-amber-50);
	color: var(--ion-color-amber-50);
	--background: var(--ion-color-amber-50);
	--color: var(--ion-color-amber-50);
}
