@import "~@fontsource/roboto/index.css";
* {
	font-family: "Roboto";
}
p {
	margin-top: 10px !important;
	margin-bottom: 2px !important;
	line-height: 1.5;
}
ion-content {
	//	--background: var(--ion-color-amber-50);
	--background: white;
	--ion-background-color: white;
}
ion-item {
	//--background: white;
	//--ion-background-color: white;
}
ion-footer {
	//--background: var(--ion-color-amber-50);
	--background: white;
	--ion-background-color: white;
}
ion-modal {
	--background: white;
	--ion-background-color: white;
}
ion-skeleton-text {
	margin-top: 1px;
	margin-bottom: 0px;
}

ion-button.edit {
	padding: 0;
	margin: 0;
}

.font-large {
	font-size: 1.1em;
}

.font-large ion-skeleton-text {
	line-height: 1.1em;
}

ion-skeleton-text {
	line-height: 1em;
}

.font-small {
	font-size: 0.9em;
	font-weight: 400;
}

.font-small ion-skeleton-text {
	line-height: 1em;
}

.lines {
	display: block;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.lines.max-5 {
	-webkit-line-clamp: 5;
}

.primary {
	color: var(--ion-color-primary) !important;
}

.secondary {
	color: var(--ion-color-secondary) !important;
}

.tertiary {
	color: var(--ion-color-tertiary) !important;
}

.success {
	color: var(--ion-color-success) !important;
}

.warning {
	color: var(--ion-color-warning) !important;
}

.danger {
	color: var(--ion-color-danger) !important;
}

.dark {
	color: var(--ion-color-dark) !important;
}

.medium {
	color: var(--ion-color-medium) !important;
}
.toolbar {
	color: var(--ion-color-medium) !important;
}
.vertical-align-sub {
	vertical-align: sub;
}
.ion-color-amber-400 {
	--ion-color-base: var(--ion-color-amber-400);
	--ion-color-base-rgb: var(--ion-color-amber-400-rgb);
	--ion-color-contrast: var(--ion-color-amber-400-contrast);
	--ion-color-contrast-rgb: var(--ion-color-amber-400-contrast-rgb);
	--ion-color-shade: var(--ion-color-amber-400-shade);
	--ion-color-tint: var(--ion-color-amber-400-tint);
}
.ion-color-toolbarbackground {
	--ion-color-base: var(--ion-color-favorite);
	--ion-color-base-rgb: var(--ion-color-favorite-rgb);
	--ion-color-contrast: var(--ion-color-favorite-contrast);
	--ion-color-contrast-rgb: var(--ion-color-favorite-contrast-rgb);
	--ion-color-shade: var(--ion-color-favorite-shade);
	--ion-color-tint: var(--ion-color-favorite-tint);
}
.ion-color-itemtoolbarbackground {
	--ion-color-base: var(--ion-color-itemtoolbarbackground);
	--ion-color-base-rgb: var(--ion-color-itemtoolbarbackground-rgb);
	--ion-color-contrast: var(--ion-color-itemtoolbarbackground-contrast);
	--ion-color-contrast-rgb: var(--ion-color-itemtoolbarbackground-contrast-rgb);
	--ion-color-shade: var(--ion-color-itemtoolbarbackground-shade);
	--ion-color-tint: var(--ion-color-itemtoolbarbackground-tint);
}
.ion-color-amber-900 {
	--ion-color-base: var(--ion-color-amber-900);
	--ion-color-base-rgb: var(--ion-color-amber-900-rgb);
	--ion-color-contrast: var(--ion-color-amber-900-contrast);
	--ion-color-contrast-rgb: var(--ion-color-amber-900-contrast-rgb);
	--ion-color-shade: var(--ion-color-amber-900-shade);
	--ion-color-tint: var(--ion-color-amber-900-tint);
}
.ion-color-amber-50 {
	--ion-color-base: var(--ion-color-amber-50);
	--ion-color-base-rgb: var(--ion-color-amber-50-rgb);
	--ion-color-contrast: var(--ion-color-amber-50-contrast);
	--ion-color-contrast-rgb: var(--ion-color-amber-50-contrast-rgb);
	--ion-color-shade: var(--ion-color-amber-50-shade);
	--ion-color-tint: var(--ion-color-amber-50-tint);
}

.ion-color-amber-100 {
	--ion-color-base: var(--ion-color-amber-100);
	--ion-color-base-rgb: var(--ion-color-amber-100-rgb);
	--ion-color-contrast: var(--ion-color-amber-100-contrast);
	--ion-color-contrast-rgb: var(--ion-color-amber-100-contrast-rgb);
	--ion-color-shade: var(--ion-color-amber-100-shade);
	--ion-color-tint: var(--ion-color-amber-100-tint);
}
.ion-color-amber-200 {
	--ion-color-base: var(--ion-color-amber-200);
	--ion-color-base-rgb: var(--ion-color-amber-200-rgb);
	--ion-color-contrast: var(--ion-color-amber-200-contrast);
	--ion-color-contrast-rgb: var(--ion-color-amber-200-contrast-rgb);
	--ion-color-shade: var(--ion-color-amber-200-shade);
	--ion-color-tint: var(--ion-color-amber-200-tint);
}
.ion-color-amber-300 {
	--ion-color-base: var(--ion-color-amber-300);
	--ion-color-base-rgb: var(--ion-color-amber-300-rgb);
	--ion-color-contrast: var(--ion-color-amber-300-contrast);
	--ion-color-contrast-rgb: var(--ion-color-amber-300-contrast-rgb);
	--ion-color-shade: var(--ion-color-amber-300-shade);
	--ion-color-tint: var(--ion-color-amber-300-tint);
}
.ion-color-amber-950 {
	--ion-color-base: var(--ion-color-amber-950);
	--ion-color-base-rgb: var(--ion-color-amber-950-rgb);
	--ion-color-contrast: var(--ion-color-amber-950-contrast);
	--ion-color-contrast-rgb: var(--ion-color-amber-950-contrast-rgb);
	--ion-color-shade: var(--ion-color-amber-950-shade);
	--ion-color-tint: var(--ion-color-amber-950-tint);
}

:root {
	--toolbar-background: #f59e0bb3;
	--ion-color-toolbarbackground: #f59e0bb3;
	--ion-color-toolbarbackground-rgb: 158, 11, 179;
	--ion-color-toolbarbackground-contrast: #ffffff;
	--ion-color-toolbarbackground-contrast-rgb: 255, 255, 255;
	--ion-color-toolbarbackground-shade: #8b0a9e;
	--ion-color-toolbarbackground-tint: #a823bb;

	--ion-color-itemtoolbarbackground: #f6ba6a;
	--ion-color-itemtoolbarbackground-rgb: 246, 186, 106;
	--ion-color-itemtoolbarbackground-contrast: var(--ion-color-step-900);
	--ion-color-itemtoolbarbackground-contrast-rgb: 0, 0, 0;
	--ion-color-itemtoolbarbackground-shade: #d8a45d;
	--ion-color-itemtoolbarbackground-tint: #f7c179;

	--ion-item-background: var(--ion-color-amber-50);
	--ion-item-color: var(--ion-color-amber-950);

	//--ion-background-color: #f59e0bb3;

	--ion-background-color: #f59e0bb3;

	--ion-color-amber-400: #fbbf24;
	--ion-color-amber-400-rgb: 251, 191, 36;
	--ion-color-amber-400-contrast: #000000;
	--ion-color-amber-400-contrast-rgb: 0, 0, 0;
	--ion-color-amber-400-shade: #dda820;
	--ion-color-amber-400-tint: #fbc53a;

	--ion-color-amber-300: #fcd34d;
	--ion-color-amber-300-rgb: 252, 211, 77;
	--ion-color-amber-300-contrast: #000000;
	--ion-color-amber-300-contrast-rgb: 0, 0, 0;
	--ion-color-amber-300-shade: #deba44;
	--ion-color-amber-300-tint: #fcd75f;

	--ion-color-amber-50: #fffbeb;
	--ion-color-amber-50-rgb: 255, 251, 235;
	--ion-color-amber-50-contrast: #000000;
	--ion-color-amber-50-contrast-rgb: 0, 0, 0;
	--ion-color-amber-50-shade: #e0ddcf;
	--ion-color-amber-50-tint: #fffbed;

	--ion-color-amber-100: #fef3c7;
	--ion-color-amber-100-rgb: 254, 243, 199;
	--ion-color-amber-100-contrast: #000000;
	--ion-color-amber-100-contrast-rgb: 0, 0, 0;
	--ion-color-amber-100-shade: #e0d6af;
	--ion-color-amber-100-tint: #fef4cd;

	--ion-color-amber-200: #fde68a;
	--ion-color-amber-200-rgb: 253, 230, 138;
	--ion-color-amber-200-contrast: #000000;
	--ion-color-amber-200-contrast-rgb: 0, 0, 0;
	--ion-color-amber-200-shade: #dfca79;
	--ion-color-amber-200-tint: #fde996;

	--ion-color-amber-900: #78350f;
	--ion-color-amber-900-rgb: 120, 53, 15;
	--ion-color-amber-900-contrast: #ffffff;
	--ion-color-amber-900-contrast-rgb: 255, 255, 255;
	--ion-color-amber-900-shade: #6a2f0d;
	--ion-color-amber-900-tint: #864927;

	--ion-color-amber-950: ##451a03;
	--ion-color-amber-950-rgb: 0, 0, 0;
	--ion-color-amber-950-contrast: #ffffff;
	--ion-color-amber-950-contrast-rgb: 255, 255, 255;
	--ion-color-amber-950-shade: #000000;
	--ion-color-amber-950-tint: #1a1a1a;

	--toolbar-background: #f59e0bb3;
	--ion-color-toolbarbackground: #f59e0bb3;
	--ion-color-toolbarbackground-rgb: 158, 11, 179;
	--ion-color-toolbarbackground-contrast: #ffffff;
	--ion-color-toolbarbackground-contrast-rgb: 255, 255, 255;
	--ion-color-toolbarbackground-shade: #8b0a9e;
	--ion-color-toolbarbackground-tint: #a823bb;

	--ion-background-color-rgb: 69, 26, 3;

	--ion-text-color: #451a03;
	--ion-text-color-rgb: 28, 25, 23;

	--ion-color-step-50: #efefee;
	--ion-color-step-100: #e4e4e2;
	--ion-color-step-150: #d9d8d7;
	--ion-color-step-200: #cecdcc;
	--ion-color-step-250: #c3c2c1;
	--ion-color-step-300: #b7b7b5;
	--ion-color-step-350: #acabaa;
	--ion-color-step-400: #a1a09f;
	--ion-color-step-450: #969593;
	--ion-color-step-500: #8b8a88;
	--ion-color-step-550: #807e7d;
	--ion-color-step-600: #757371;
	--ion-color-step-650: #6a6866;
	--ion-color-step-700: #5f5d5b;
	--ion-color-step-750: #545150;
	--ion-color-step-800: #484644;
	--ion-color-step-850: #3d3b39;
	--ion-color-step-900: #322f2e;
	--ion-color-step-950: #272422;

	--ion-segment-button {
		--indicator-color: #08a391;
	}

	/* Material Design styles */
	--ion-segment-button.md {
		--color: #000;
		--color-checked: #08a391;
		--indicator-height: 4px;
	}

	/* iOS styles */
	--ion-segment-button.ios {
		--color: #08a391;
		--color-checked: #fff;
		--border-radius: 20px;
	}
}
