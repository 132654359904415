.card-hero-wrapper {
	position: relative;
	background-color: var(--ion-color-amber-100);
	height: 200px;

	.card-hero {
		&::part(image) {
			object-fit: cover;
		}
	}
	.card-avatar-wrapper {
		position: absolute;
		left: 140px;
		top: 12px;

		width: 180px;
		height: 180px;
		border-radius: 95px;
		background-color: #fff;
		ion-img.card-avatar {
			width: 150px;
			height: 150px;
			&::part(image) {
				border-radius: 80px;
			}
			margin: 0 auto;
			margin-top: 15px;
		}
	}

	.menu-wrapper {
		position: absolute;
		left: 285px;
		top: 45px;
		right: 140px;
		height: 112px;
		border-radius: 18px;
		background-color: #fff;
		padding-left: 50px;

		.title-text {
			font-size: 18px;
			line-height: 22px;
			text-transform: uppercase;
			margin-top: 17px;
		}

		.welcome-text {
			font-size: 40px;
			line-height: 48px;
		}
	}

	@media (max-width: 1280px) {
		height: 110px;

		.card-hero {
			&::part(image) {
				//object-fit: contain;
				height: 110px;
			}
		}

		.card-avatar-wrapper {
			display: none;
		}

		.menu-wrapper {
			top: 29px;
			left: 20px;
			right: 20px;
			background-color: transparent;
			height: auto;
			padding-left: 0;

			.title-text {
				font-size: 16px;
				line-height: 28px;
				margin-top: 0px;
			}

			.welcome-text {
				font-size: 24px;
				line-height: 29px;
			}
		}
	}
}
