.event-view-switcher {
	display: flex;
	justify-content: space-between;

	.icons {
		margin-top: 25px;

		ion-icon {
			height: 26px;
			width: 26px;
			cursor: pointer;

			&:first-of-type {
				margin-right: 21px;
			}
		}
	}
}
